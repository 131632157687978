<template>
    <div>
        <b-modal id="modal-errorMsg" centered hide-footer @close="$root.clearErrorMsg" @hidden="$root.clearErrorMsg">
            <div class="text-center">
                <img v-if="$root.variant == 'danger' || $root.variant == 'warning'"
                    src="./assets/images/actions/error.png" alt="" height="100px" width="100px">

                <img v-else-if="$root.variant == 'info'" src="./assets/images/actions/info.png" alt="" height="100px"
                    width="100px">
                <img src="./assets/images/actions/success.png" v-else alt="" height="100px" width="100px">

                <p class="dialog-title" v-html="$root.modal.title"></p>
                <p class="dialog-msg font-weight-normal">{{ $root.modal.msg }}</p>
                <div v-if="$root.modal.action">
                    <b-button v-if="$root.modal.actionType='topup'" class="button-topup px-3" @click="$bvModal.hide('modal-errorMsg'); toTopup()"
                        style="border-radius: 10px;">
                        <span class="text-primary">{{ $t('topup') }}</span>
                    </b-button>

                </div>

            </div>
        </b-modal>
        <b-modal id="modal-setupPwd" centered hide-footer hide-header no-close-on-backdrop>
            <div class="text-center">
                <img src="./assets/images/actions/info.png" alt="" height="100px" width="100px">

                <p class="dialog-title">{{ $t('set_secpwd') }}</p>
                <p class="dialog-msg font-weight-normal">{{ $t('set_secpwd_hint') }}</p>
                <div>
                    <b-button variant="primary"
                        @click="$bvModal.hide('modal-setupPwd'); $router.push('/web/sessions/set_secpassword')"
                        class="font-weight-600">{{
                            $t('setup_now') }}</b-button>
                </div>

            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    methods: {
        toTopup() {
            var token = localStorage.getItem('currencyToken');
            var lang = localStorage.getItem('lan');
            var baseUrl = window.location.hostname === 'mall.jaboclub.com' ? 'https://jaboclub.com/web/deposit' : 'https://uat.jaboclub.com/web/deposit';

            if (token) {
                var url = `${baseUrl}?key=${encodeURIComponent(token)}&lan=${encodeURIComponent(lang)}`;
                window.location.href = url;
            } else {
                console.error('Token not found in localStorage');
            }
        },
    }
}
</script>